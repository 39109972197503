import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import title_image from '../../../images/titles/value_targeting_and_business_analysis.jpg'
import person from '../../../images/person.png'

const pageTitle = "Value Targeting and Business Analysis";
const pageSLug = "value-targeting-and-business-analysis";
class ValueTargeting extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Together we can identify, validate, and prioritize value-creation 
            opportunities to create and achieve advancement in all fields.</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Successful organizations are always maximizing their business models to maintain their 
                    competitive advantage and deliver value to both their internal and external stakeholders.</h3>
                  <span className="break"></span>
                  <h4>Our Value Targeting & Business Analysis Approach</h4>
                  <p>Our experts have developed a wide range of business diagnostic and analytical techniques to help 
                      organizations identify, validate, and prioritize value creation opportunities. Our experienced 
                      management consultants bring healthy mixes of industry, strategy, operations, and financial 
                      experience to assist our clients.</p>
                    <h6>Our capabilities in this area include:</h6>
                    <ul>
                        <li>Voice of customer analysis</li>
                        <li>Cost of structure and value driver analysis</li>
                        <li>Internal Business and operational Assessments (Organization, Processes, Technology)</li>
                        <li>Market Analysis and Competitive Benchmark</li>
                    </ul>
                    <span className="break"></span>
                    <h3 className="intext">Finding Value-Creation Opportunities that will serve you better.</h3>
                    <p>To achieve and maintain a competitive advantage, an organization must continually assess its 
                        Strengths, Weaknesses, Opportunities, and Threats. (SWOT)</p>
                    <ul>
                        <li>Strategic Opportunities and Threats</li>
                        <li>Competitive threats from higher quality, lower-cost providers</li>
                        <li>Opportunities to expand</li>
                        <li>Increase in Regulation</li>
                        <li>Offshoring opportunities</li>
                    </ul>
                    <span className="break"></span>
                    <h4>Operational challenges</h4>
                    <ul>
                        <li>Customer service issues</li>
                        <li>Lack of focus on core processes</li>
                        <li>Degrading cost-effectiveness – when compared to market competitors</li>
                        <li>Process inefficiencies- the proliferation of non-value, redundant added activities</li>
                        <li>Building a performance-based/continuous improvement culture</li>
                        <li>Functional silos – lack of end-to-end process integration</li>
                        <li>Misalignment of roles and responsibilities based on their specific skill levels</li>
                    </ul>
                    <span className="break"></span>
                    <h4>Competitive Positioning and Market Analysis</h4>
                    <p>Comprehending your situation, understanding external business drivers, and competitive/market positioning 
                        is critical in gaining market share. Competitive, industry, regulatory, and technology changes can create 
                        threats or opportunities for a company. We leverage industry and market research data, to analyze and 
                        characterize critical trends and business drivers that exist in these unstable and quick to change areas. 
                        We work with our clients to discover the long term/ short term implications and probe on positive or negative 
                        consequences. Finally, we help assess the impact and effect on the organization, prioritizing the importance 
                        of each respective business driver.</p>
                    <span className="break"></span>
                    <h4>Customer Voice analysis</h4>
                    <p>Understanding customer feedback is crucial to comprehending customer perspectives on current performance and 
                        future customer perspectives, to better fulfill customer needs/desires. Having a close working relationship 
                        with customers is a vital part of success. Our experts work with you to gather and analyze customer data in 
                        a structured and objective manner. We accomplish this through a variety of techniques, including customer 
                        surveys, customer interviews, and existing customer data. We help organizations distill the essential findings 
                        and then incorporate them into other assessment and strategic planning activities.</p>
                        <span className="break"></span>
                        <div className="contact-box">
                    <h3>Interested in how strategy and vision development can help develop your business?</h3>
                        <span className="break"></span>
                        <div className="button-area">
                            <Link to="/contact-us" className="button default">Contact us today</Link>
                        </div>
                    </div>
                </div>
               {/* <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default ValueTargeting
